/**
* CustomerEntityHelper.ts (abstractuser) *

* Copyright © 2024 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2024
* @file CustomerEntityHelper.ts
* @author Rafael Rodrigues
* @copyright 2024 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import * as CryptoJS from 'crypto-js';

const TrackAndUnsubscribeEmailSecretKey: string = 'f5da4f262e1ae2ede2b0422b6f6992f3'

/**
 * Use this file to centralize all helpers related to the Customer/User entity or rules.
 * @module CustomerEntityHelper
 */
export class CustomerEntityHelper {
  /**
   * Get hashed customer email
   * @param {string} customerEmail Defines the customer email to be hashed
   * @returns {string}
  */
  public static getHashedCustomerEmail(customerEmail: string): string {
    return CryptoJS.SHA256(customerEmail).toString(
      CryptoJS.enc.Hex
    );
  }

  /**
   * Encrypts a given value
   * @param {string} valueToEncrypt Defines value to be encrypted
   * @returns {string}
  */
  public static encryptValue(valueToEncrypt: string): string {
    const key: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(TrackAndUnsubscribeEmailSecretKey);

    // Generate a random 16-byte
    const previousCiphertextBlock: CryptoJS.lib.WordArray = CryptoJS.lib.WordArray.random(16);

    // Encrypt the value
    const encrypted = CryptoJS.AES.encrypt(valueToEncrypt, key, {
      iv: previousCiphertextBlock,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    // Returns the encrypted value
    return previousCiphertextBlock.toString(CryptoJS.enc.Hex) + ':' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  }

  /**
   * Decrypts a given value
   * @param {string} valueToDecrypt Defines value to be decrypted
   * @returns {string}
  */
  public static decryptValue(valueToDecrypt: string): string {
    const key: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(TrackAndUnsubscribeEmailSecretKey);

    const [previousCiphertextHex, encryptedHex] = valueToDecrypt.split(':');
    
    const previousCiphertextBlock: CryptoJS.lib.WordArray = CryptoJS.enc.Hex.parse(previousCiphertextHex);
    const encryptedCipherText: CryptoJS.lib.CipherParams = CryptoJS.enc.Hex.parse(encryptedHex) as unknown as CryptoJS.lib.CipherParams;
    
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedCipherText },
      key,
      {
        iv: previousCiphertextBlock,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}